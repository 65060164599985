import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Ticket from "../../Components/Ticket/Ticket";
import styles from "./VerTickets.module.scss";
import variables from "../../Context/Variables";
import TicketSkeleton from "../../Components/Ticket/Skeleton/TicketSkeleton";
import ErrorPage from "../../Components/ErrorPage/ErrorPage";
import { Icon } from "@iconify/react";

const VerTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { token, nombreUsuario, dni } = useParams();

  const normalizarNombre = (nombre) => {
    return nombre ? nombre.replace(/\+/g, ' ') : nombre;
  };

  useEffect(() => {
    document.title = "Ver Tickets";
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${variables.API_BASE_URL}/api/tickets/${token}`
      );

      setTickets(response.data);
      console.log(response.data);
      
      setCargando(false);
    } catch (error) {
      console.error("Error fetching tickets:", error);
      setError("Error al buscar los tickets.");
      setCargando(false);
    }
  };

  if (cargando)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2
          style={{
            color: "#ccc",
            fontSize: "20px",
            marginTop: "16px",
          }}
        >
          Cargando tickets...
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TicketSkeleton />
          <TicketSkeleton />
          <TicketSkeleton />
        </div>
      </div>
    );

  if (error) return <ErrorPage />;

  return (
    <section className={styles.cuerpoTickets}>
      <div className={styles.infoTickets}>
        <h4 className={styles.tituloEntradas}>
          Tus tickets <Icon icon="solar:check-circle-bold" style={{ color: "#47BD8F" }} />
        </h4>
        <div className={styles.fondoSub}>
          <p>
            Aquí puedes ver tus tickets comprados.
            <br />
            Recuerda presentar tus tickets en el punto de ingreso del evento.
          </p>
        </div>
      </div>

      <div className={styles.lineaDivisora}></div>

      <div className={styles.ticketsContainer}>
        {tickets.length > 0 ? (
          tickets.map((ticket) => (
            <Ticket 
              key={ticket.id} 
              ticket={{
                ...ticket,
                nombreUsuario: normalizarNombre(nombreUsuario) || ticket.nombreUsuario,
                dni: dni || ticket.dni
              }} 
            />
          ))
        ) : (
          <p
            style={{
              width: "80%",
              textAlign: "center",
              margin: "40px auto",
            }}
          >
            No tienes tickets comprados.
          </p>
        )}
      </div>
    </section>
  );
};

export default VerTickets;