import React, { useState } from "react";
import styles from "./LoginWindow.module.scss";
import { Link } from "react-router-dom";
import { useAuth } from "../../Context/AuthProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";
import SubmitButton from "../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton";
import variables from "../../Context/Variables";
import LoaderBolas from "../../Components/Buttons-loaders-inputs/LoaderGoogle/LoaderBolas";
import { Icon } from "@iconify/react";

const LoginWindow = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mantenerSesion, setMantenerSesion] = useState(false);
  const [error, setError] = useState("");
  const [mostrarPassword, setMostrarPassword] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  const [isLoading,setIsLoading] = useState(false);
  const [googleLoading,setGoogleLoading] = useState(false); 

  const apiUrl = variables.API_BASE_URL + "/api/usuarios/login";

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isFormValid()) return;
  
    setIsLoading(true); // Indicar que la carga ha comenzado
  
    try {
      const response = await axios.post(apiUrl, { email, password });
      console.log("RESPONSE.DATA:" + JSON.stringify(response.data));
  
      login(response.data, mantenerSesion); 
      const rol = response.data.rol;
      if (rol === "PRODUCTOR") {
        navigate("/panel-productor");
      } else if(rol === "STAFF"){
        navigate("/mis-eventos");
      } else if(rol ==="ADMIN"){//nuevas lineas para redirigir a admin
        navigate("/panel-admin");
      }else{
        navigate("/index");
      }
    } catch (error) {
      // Verifica si hay un error de respuesta y un estado
      if (error.response && error.response.status) {
        const { status, data } = error.response;
        // Manejo de errores específicos basado en el estado de la respuesta
        switch (status) {
          case 400:
            setError("No existe un usuario con ese email.");
            break;
          case 401:
            setError("Email o contraseña incorrecta.");
            break;
          case 403:
            setError("Ingrese desde su cuenta de Google.");
            break;
          default:
            setError("Ocurrió un error, vuelva a intentarlo.");
        }
      } else {
        // Si no hay información de la respuesta, muestra un error genérico
        setError("Ocurrió un error, vuelva a intentarlo.");
      }
    } finally {
      setIsLoading(false); // Indica que la carga ha terminado
    }
  };  

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const isFormValid = () => {
    return isValidEmail(email) && password.length > 0;
  };

  const togglePasswordVisibility = () => {
    setMostrarPassword(!mostrarPassword);
  };

  function handleError() {
    setError(
      "No se pudo iniciar sesión con Google. Por favor, inténtalo de nuevo."
    );
  }

  const handleGoogleLogin = async (googleData) => {
    setGoogleLoading(true)
    const apiUrl = variables.API_BASE_URL + "/api/usuarios/authGoogle";
    try {
      // Enviar el token al backend
      const response = await axios.post(apiUrl, {
        tokenId: googleData.credential, 
      });

      // Si el backend valida el token y encuentra/crea el usuario correctamente
      if (response.data && response.data.jwt) {
        login(response.data, mantenerSesion); 
        navigate("/index"); 
      } else {
        // Maneja casos donde la respuesta no es la esperada
        setError(
          "No se pudo iniciar sesión con Google. Por favor, inténtalo de nuevo."
        );
        setGoogleLoading(false)
      }
    } catch (error) {
      // Manejar errores de la petición
      console.error("Error al iniciar sesión con Google:", error);
      setError(
        "Error al iniciar sesión con Google. Por favor, inténtalo de nuevo."
      );
      setGoogleLoading(false)
    }
  };

  return (
    <section className={styles.grupoLogin}>
      <div className={styles.fotoLogin}></div>
      <div className={`${styles.loginForm} ${styles.parteFormLogin}`}>
        <h3>Ingresa a tu cuenta</h3>
        {googleLoading ? <LoaderBolas size={100} /> : <GoogleLogin onSuccess={handleGoogleLogin} onError={handleError} className={styles.google} />}
        <small>o</small>
        <form
          onSubmit={handleSubmit}
          autoComplete="on"
          className={styles.formulario}
        >
          {error && <div className={styles.errorMesagge}>{error}</div>}

          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            placeholder="ejemplo@mail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="username"
            className={styles.formControl}
          />

          <label htmlFor="password">Contraseña</label>
          <div className={styles.inputGroup}>
            <input
              type={mostrarPassword ? "text" : "password"}
              id="password"
              placeholder="Ingresa tu contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
              className={styles.passwordInput}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className={styles.togglePassword}
              aria-label={
                mostrarPassword ? "Ocultar contraseña" : "Mostrar contraseña"
              }
            >
              {mostrarPassword ? (
              <Icon width={20} icon="mdi:eye-off" />
            ) : (
              <Icon width={20} icon="mdi:eye" />

            )}
            </button>
          </div>

          <div className={styles.containerCheckbox}>
            <input
              type="checkbox"
              id="mantenerSesion"
              checked={mantenerSesion}
              onChange={(e) => setMantenerSesion(e.target.checked)}
            />
            <label htmlFor="mantenerSesion" className={styles.checkboxLabel}>
              <svg viewBox="0 0 64 64" height="1em" width="1em">
                <path
                  d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                  className={styles.path}
                ></path>
              </svg>
              Permanecer conectado
            </label>
          </div>

          <SubmitButton
            isDisabled={!isFormValid() || isLoading}
            color="hsl(0, 0%, 15%)" // El color deseado para el botón
            isLoading={isLoading}
            onClick={handleSubmit} // Este onClick podría no ser necesario ya que es un botón de tipo submit
          >
            Iniciar sesión
          </SubmitButton>
          
          <div className={styles.links}>
            <p>¿No tienes una cuenta?</p>
            <Link to="/signup">Regístrate ahora</Link>
    
            <p>¿Olvidaste tu contraseña?</p>
            <Link to="/recuperar-contraseña">Recupera tu contraseña</Link>
          </div>
        </form>
      </div>
    </section>
  );
};

export default LoginWindow;
