import React from 'react';
import styles from "../../Pages/generar-y-enviar-qr/EnviarQrsPage.module.scss";
import { Link } from 'react-router-dom';
import { Icon } from "@iconify/react";

const TiposDeTicketsComponent = ({ tiposDeTickets, eventoId, nombreEvento }) => {
    return tiposDeTickets.map((ticket) => {
        const agotado = ticket.cantidadVendida >= ticket.cantidadLimite;
        const encodedNombreEvento = encodeURIComponent(nombreEvento);
        const encodedNombreTicket = encodeURIComponent(ticket.nombre);

        return (
            <div key={ticket.id} className={styles.tipoTicketGeneral}>
                <div className={styles.detalleTipoTicket}>
                    <h4>{ticket.nombre}</h4>
                    <h5 style={{ color: agotado ? "red" : "" }}>
                        {agotado ? "AGOTADOS" : `$${ticket.precio}`}
                    </h5>
                </div>
                {agotado ? (
                    <div className={styles.resultadoCantidad} style={{ color: "red", minWidth: "120px", textAlign: "center" }}>
                        AGOTADOS
                    </div>
                ) : (
                    <div className={styles.botonGenerar}>
                        <Link to={`/generar-y-enviar-qr/${eventoId}/${ticket.id}/${encodedNombreEvento}/${encodedNombreTicket}`}>
                            Generar QRs <Icon icon="mingcute:add-fill" />
                        </Link>
                    </div>
                )}
            </div>
        );
    });
};

export default TiposDeTicketsComponent;

