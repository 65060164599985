import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import styles from './Ticket.module.scss';
import { Icon } from "@iconify/react";

const Ticket = ({ ticket }) => {
    const ticketRef = useRef(null);
    const downloadButtonRef = useRef(null); 

    const descargarEntrada = () => {
        if (ticketRef.current) {
            // Ocultar el botón antes de capturar
            if (downloadButtonRef.current) {
                downloadButtonRef.current.style.display = "none";
            }

            html2canvas(ticketRef.current, { allowTaint: true, useCORS: true })
                .then((canvas) => {
                    const link = document.createElement('a');
                    link.href = canvas.toDataURL('image/png');
                    link.download = `TICKET-ID:${ticket.id}.png`; // Nombre del archivo
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch((error) => {
                    console.error('Algo salió mal!', error);
                })
                .finally(() => {
                    // Restaurar el botón después de capturar
                    if (downloadButtonRef.current) {
                        downloadButtonRef.current.style.display = "block";
                    }
                });
        }
    };

    // Generar una query string para evitar caché en imágenes
    const randomQueryStringVerificado = "?nocache=" + new Date().getTime();
    const srcVerificado = "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Tickets/Verified.svg.png" + randomQueryStringVerificado;

    return (
        <div ref={ticketRef} className={styles.ticketCont}>
            <div className={styles.logoTicket}>
                <div className={styles.detallesTicket}>
                    <p className={styles.textoVerificado}>
                        TICKET SIMPLEPASS VERIFICADO <img src={srcVerificado} alt="Verificado" crossOrigin="anonymous" />
                    </p>
                    <p className={styles.id}>ID: {ticket.id}</p>
                </div>
                <div className={styles.contTitulo}>
                    <h1>simple<span>pass</span></h1>
                </div>
            </div>

            <div className={styles.datosTicket}>
                <h2>{ticket.titulo}</h2>
                <span>Tipo de entrada: {ticket.tipoEntrada}</span>
                {ticket.nombreUsuario && <span id={styles.infoUsuario}>{ticket.nombreUsuario}</span>}
                {ticket.dni && <span id={styles.dni}>DNI: {ticket.dni}</span>}
            </div>

            <div className={styles.imagenQR}>
                <img src={ticket.srcQr + "?nocache=" + new Date().getTime()} alt="Código QR" crossOrigin="anonymous" />
            </div>

            <button
                ref={downloadButtonRef} // Añadir referencia al botón
                className={styles.btnDescargar}
                onClick={descargarEntrada}
            >
                Descargar <Icon icon="solar:download-minimalistic-bold" width={16} />
            </button>
        </div>
    );
};

export default Ticket;