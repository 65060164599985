import styles from './ConfianEnNosotros.module.scss';
import React from 'react';

const img1 = "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/logos-clientes/viale_1.webp";
const img2 = "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/logos-clientes/guiso.webp";
const img3 = "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/logos-clientes/carnavales.webp";
const img4 = "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/logos-clientes/fna.webp";
const img5 = "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/logos-clientes/arizona_1.webp";
const img6 = "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/INDEX/estudio-marketing.webp";


const ConfianEnNosotros = () => {
    
  return (
    <div className={styles.infoGoogle}>
      <div className={styles.container}>
        <div className={styles.comments}>
          <p className={styles.comment}>Confían en nosotros</p> 
          <div className={styles.imageContainer}>
            <img src={img1} alt="1" className={styles.image} />
            <img src={img2} alt="2" className={styles.image} />
            <img src={img3} alt="3" className={styles.image} />
            <img src={img4} alt="4" className={styles.image} />
            <img src={img5} alt="5" className={styles.image} />
            <img src={img6} alt="6" className={styles.image} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfianEnNosotros;