import React, { useState } from 'react';
import styles from './ContactSection.module.scss';
import Alert from "../../Alert/Alert";
import variables from "../../../Context/Variables";
import SubmitButton from "../../Buttons-loaders-inputs/SubmitButton/SubmitButton"; // Asegúrate de importar el componente correctamente

const ContactSection = () => {
    const [isLoading, setIsLoading] = useState(false);

    const handleSendMessage = async (event) => {
        event.preventDefault();

        const form = event.currentTarget; // Usamos el formulario directamente
        const nombreApellido = form[0].value.trim();
        const telefono = form[1].value.trim();
        const mensaje = form[2].value.trim();

        // Validar campos vacíos
        if (!nombreApellido || !telefono || !mensaje) {
            await Alert({
                tipo: 'warning',
                titulo: 'Error de validación',
                descripcion: 'Todos los campos son obligatorios. Por favor, complétalos.',
                duracion: 4000,
            });
            return;
        }

        // Enviar datos al backend
        const formData = {
            nombreApellido,
            telefono,
            mensaje,
        };

        setIsLoading(true);

        try {
            const response = await fetch(`${variables.API_BASE_URL}/api/contact/send`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                await Alert({
                    tipo: 'success',
                    titulo: '¡Éxito!',
                    descripcion: 'Mensaje enviado correctamente.',
                    duracion: 4000,
                });
                form.reset(); // Limpia el formulario después del envío exitoso
            } else {
                throw new Error('Error al enviar el mensaje.');
            }
        } catch (error) {
            console.error(error);
            await Alert({
                tipo: 'error',
                titulo: 'Error de envío',
                descripcion: 'No se pudo enviar el mensaje. Intenta nuevamente más tarde.',
                duracion: 5000,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.textContainer}>
                    <div className={styles.question}>¿Te gustaría saber más?</div>
                    <div className={styles.subheading}>Escribe aquí tu mensaje</div>
                </div>
                <form className={styles.formContainer} onSubmit={handleSendMessage}>
                    <input
                        type="text"
                        placeholder="Nombre y Apellido"
                        className={styles.input}
                        required
                    />
                    <input
                        type="tel"
                        placeholder="Número de teléfono"
                        className={styles.input}
                        required
                    />
                    <textarea
                        placeholder="Tu mensaje o consulta"
                        className={styles.textarea}
                        required
                    ></textarea>
                    <SubmitButton
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        className={styles.sendMessageButton}
                    >
                        Enviar Mensaje
                        
                    </SubmitButton>
                </form>
            </div>
        </div>
    );
};

export default ContactSection;
