import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import Countdown from 'react-countdown';
import styles from "./CarnavalesHasenkamp.module.scss";
import { Icon } from '@iconify/react/dist/iconify.js';
import axios from 'axios';
import variables from '../../Context/Variables';
import EventCard from '../../Components/EventCard/EventCard';
import EventosNoEncontrados from '../../Components/EventosNoEncontrados/EventosNoEncontrados';
import LoaderSimplepass from '../../Components/Buttons-loaders-inputs/LoaderSimplepass/LoaderSimplepass';

const CarnavalesHasenkamp = () => {
    const [eventos, setEventos] = useState([]);
    const [error, setError] = useState(null);
    const [estaBuscando, setEstaBuscando] = useState(true);
    const [activeSection, setActiveSection] = useState('proximos');
    const eventosBuscados = useRef(false);

    useEffect(() => {
        document.title = "Carnavales de Hasenkamp 2025";
    }, []);

    useEffect(() => {
        const busqueda = "Carnavales";

        if (!eventosBuscados.current) {
            eventosBuscados.current = true;
            const buscarEventos = () => {
                axios.get(`${variables.API_BASE_URL}/api/eventos/search?q=${busqueda}`)
                    .then(response => {
                        setEventos(response.data);
                        setEstaBuscando(false);
                    })
                    .catch(err => {
                        setError(err.message);
                        setEstaBuscando(false);
                    });
            }
            buscarEventos();
        }
    }, []);

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>¡El carnaval ha comenzado!</span>;
        } else {
            return (
                <div className={styles.countdown}>
                    <div>
                        <span>{days}</span> días
                    </div>
                    <div>
                        <span>{hours}</span> horas
                    </div>
                    <div>
                        <span>{minutes}</span> minutos
                    </div>
                    <div>
                        <span>{seconds}</span> segundos
                    </div>
                </div>
            );
        }
    };

    return (
        <>
            <Helmet>
                <title>Carnavales de Hasenkamp 2025 - Entradas Oficiales</title>
                <meta name="description" content="Viví la magia del Carnaval en Hasenkamp 2025. Comparsas, música en vivo, gastronomía y más. ¡Conseguí tus entradas oficiales!" />
                <meta name="keywords" content="Carnavales, Hasenkamp, Entre Ríos, entradas, tickets, eventos, 2025, comparsas" />
                <meta property="og:title" content="Carnavales de Hasenkamp 2025 - Entradas Oficiales" />
                <meta property="og:description" content="Viví la magia del Carnaval en Hasenkamp 2025. Comparsas, música en vivo, gastronomía y más. ¡Conseguí tus entradas oficiales!" />
                <meta property="og:image" content="[URL_IMAGEN_CARNAVAL]" />
                <meta property="og:url" content={window.location.href} />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>

            <div className={styles.body}>
                <div className={styles.heroSection}>
                    <div className={styles.videoBackground}>
                        <video autoPlay loop muted playsInline>
                            <source src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/carnavales+logo/Carnavales+de+Hasenkamp+2023.mp4" type="video/mp4" />
                        </video>
                    </div>

                    <div className={styles.heroContent}>
                        <h1>
                            <span className={styles.carnival}>Carnavales de Hasenkamp</span>
                            <span className={styles.location}> 2025</span>
                        </h1>
                        <div className={styles.heroDescription}>
                            <p>¡Los mejores carnavales de la Costa del Paraná!</p>
                            <p> <Icon width={16} icon="fluent-emoji:party-popper" /> Viví el carnaval con nosotros <Icon width={16} icon="fluent-emoji:party-popper" /> </p>
                            <div className={styles.countdown}>
                                <Countdown date={new Date('2025-01-10T00:00:00')} renderer={renderer} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.navigationTabs}>
                    <h2>Compra tus tickets acá <Icon width={20} icon="lucide:tickets" /></h2>
                </div>

                <div className={styles.paymentMethods}>
                    <h3>Métodos de pago disponibles</h3>
                    <div className={styles.contenedorMetodosDePago}>
                        <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/metodosPago/logo-Mastercard.webp" className={styles.mc} alt="Logo Mastercard" />
                        <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/metodosPago/Mercado-Pago-Logo.webp" className={styles.mp} alt="Logo Mercado Pago" />
                        <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/metodosPago/new-visa-logo-high-quality-png-latest.webp" className={styles.visa} alt="Logo Visa" />
                        <div className={styles.tarjeta}><span>Crédito</span></div>
                        <div className={styles.tarjeta}><span>Débito</span></div>
                    </div>
                </div>

                <section className={styles.eventos}>
                    {estaBuscando ? (
                        <div className={styles.loaderContainer}>
                            <LoaderSimplepass />
                        </div>
                    ) : (
                        eventos.length === 0 ? (
                            <EventosNoEncontrados query={"Carnavales de Hasenkamp"} />
                        ) : (
                            <div className={styles.eventoscont}>
                                {eventos
                                    .sort((a, b) => {
                                        const [dayA, monthA, yearA] = a.fechaRealizacion.split(' ')[0].split('/');
                                        const [dayB, monthB, yearB] = b.fechaRealizacion.split(' ')[0].split('/');
                                        const dateA = new Date(yearA, monthA - 1, dayA);
                                        const dateB = new Date(yearB, monthB - 1, dayB);
                                        return dateA - dateB;
                                    })
                                    .map(evento => (
                                        <EventCard key={evento.id} evento={evento} />
                                    ))
                                }
                            </div>
                        )
                    )}
                </section>
            </div>
        </>
    );
}

export default CarnavalesHasenkamp; 